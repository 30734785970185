<template>
  <span
    :class="className"
    :style="cssVars"
    @click="$emit('click')"
    @mouseover="$emit('mouseover')"
    @mouseleave="$emit('mouseleave')"
  >&nbsp;</span>
</template>

<script setup>/**
 * Icon Vue component
 *
 * See also templates/elements/icon for the Twig implementation.
 * Add new icons in templates/elements/icon/assets.
 */
import { computed } from 'vue';

const props = defineProps({
    icon: {
        type: String,
        default: 'duck-light',
    },
    size: {
        type: String,
        default: 'l',
    },
});

const className = computed(() => `icon-element icon-size-${props.size} ${props.icon}`);

const extended = computed(() => props.icon.startsWith('x-'));

const iconAssetUrl = computed(() => {
    if (extended.value) {
        return `/assets/icons/extended/${props.icon.substring(2)}.svg`;
    }
    return `/assets/icons/${props.icon}.svg`;
});

const cssVars = computed(() => ({
    'mask-image': `url('${iconAssetUrl.value}')`,
    '-webkit-mask-image': `url('${iconAssetUrl.value}')`,
}));

</script>
